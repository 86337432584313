// Overrides
@mixin btn-shadow($color) {
  background-image: linear-gradient(
    180deg,
    lighten($color, 6%) 0%,
    $color 60%,
    darken($color, 4%) 100%
  );
  filter: none;
}
@mixin btn-shadow-inverse($color) {
  background-image: linear-gradient(
    180deg,
    darken($color, 8%) 0%,
    darken($color, 4%) 40%,
    darken($color, 0%) 100%
  );
  filter: none;
}

.section {
  background-color: $body-background-color;
}

.hero {
  background-color: $grey-dark;
}

.button,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select,
.select select,
.textarea,
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  height: 2.5em;
}

.button {
  transition: all 200ms ease;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &:not(.is-outlined):not(.is-inverted) {
        @include btn-shadow($color);

        &.is-hovered,
        &:hover {
          @include btn-shadow-inverse($color);
          text-shadow: 1px 1px 1px rgba($black, 0.3);
        }
      }
    }
  }
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.card {
  border: 1px solid $border;
  border-radius: $radius;

  .card-image {
    img {
      border-radius: $radius $radius 0 0;
    }
  }

  .card-header {
    border-radius: $radius $radius 0 0;
  }

  .card-footer,
  .card-footer-item {
    border-width: 1px;
    border-color: $border;
  }
}

.navbar {
  border: 1px solid $dark;

  @include touch {
    .navbar-menu {
      background-color: inherit;
    }
  }

  .navbar-item,
  .navbar-link {
    color: rgba($white, 0.75);
    border-left: 1px solid rgba($white, 0.1);
    border-right: 1px solid rgba($black, 0.2);

    &.is-active {
      background-color: rgba($dark, 0.1);
    }

    &:hover {
      color: $white;
      border-left: 1px solid rgba($black, 0.2);
      background-color: rgba($black, 0.2);
    }
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar-item,
      .navbar-link {
        color: rgba($color-invert, 0.75);

        &.is-active {
          color: rgba($color-invert, 1);
        }
      }
    }
  }
}

.hero {
  .navbar {
    background-color: $background;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar {
        background: none;
      }
    }
  }
}

.tabs {
  a {
    &:hover {
      color: $link-hover;
      border-bottom-color: $link-hover;
    }
  }

  li {
    &.is-active {
      a {
        color: $primary-invert;
        border-bottom-color: $primary-invert;
      }
    }
  }
}

.modal {
  .modal-card-body {
    background-color: $body-background-color;
  }
}
